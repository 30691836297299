<template>
  <div class="pa-10">
    <users />
  </div>
</template>
<script>
import users from "@/components/users";
export default {
  components: {
    users,
  },
};
</script>
