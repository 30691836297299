<!--System: Whistle it 
  Module: Super Admin
  Developer: Gulraiz Ahmed 
  Edited:Hassan Ali
  Date: 17-06-2021 
  Organization: Whistle it 
  Purpose: The purpose of this component is to display Users data details  -->

<template>
  <v-container class="pa-0">
    <v-sheet
      color="white"
      :height="$vuetify.breakpoint.xs ? '130' : '83'"
      width="100%"
      :class="$vuetify.breakpoint.xs ? 'd-flex flex-column' : 'd-flex '"
      class="px-8 py-5"
    >
      <!------------------------------------------Search Textfield---------------------------------------------->
      <v-layout style="max-width: 200px">
        <v-text-field
          id="user-panel-search-field"
          v-model="search"
          placeholder="Search"
          dense
          outlined
          prepend-inner-icon="mdi mdi-magnify"
          color="admin-primary"
          autofocus
          style="max-width: 200px"
        ></v-text-field>
      </v-layout>
      <v-spacer></v-spacer>

      <v-select
        style="max-width: 200px"
        v-model="select"
        :items="companiesList"
        label="Select Company"
        dense
        solo
        outlined
      >
      </v-select>
    </v-sheet>
    <v-skeleton-loader
      v-if="firstLoad"
      :loading="loading"
      type="table-thead,table-tbody"
    ></v-skeleton-loader>
    <!---------------------------------------------Data Table------------------------------------------------>
    <v-data-table
      :headers="headers"
      :items="getUsersdata"
      v-show="!firstLoad"
      class="shadow-class"
      :search="search"
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      :item-class="rowClass"
      :loading="loading"
    >
      <!-------------------------------------------Dialogue Box for Edit------------------------------------------->
      <template v-slot:top>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row class="shadowclass">
                  <v-col cols="12" sm="6" md="4">
                    <!-----------------------------------------------Name Textfield------------------------------------------->

                    <v-text-field
                      v-model="editedItem.full_name"
                      label="Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <!-----------------------------------------------Email Textfield------------------------------------------->
                    <v-text-field
                      v-model="editedItem.email"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <!-----------------------------------------------designation Textfield------------------------------------------->
                    <v-text-field
                      v-model="editedItem.designation"
                      label="Designation"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <!-----------------------------------------------Status Textfield------------------------------------------->
                    <v-text-field
                      v-model="editedItem.custom_status"
                      label="Status"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-----------------------------------------------------Buttons------------------------------------------------->
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="saveUsersData">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-------------------------------------------Dialogue Box for delete------------------------------------------->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!------------------------------------ slot for Users creation Date ------------------------------------->
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top color="admin-tooltip-color">
          <!----------------------------------------- Edit Users Name --------------------------------------------->
          <template v-slot:activator="{ on, attrs }">
            <!-----------------------------------------Icons of Edit and Delete---------------------------------------->
            <v-icon
              disabled
              small
              v-bind="attrs"
              v-on="on"
              class="ml-n2 admin-primary--text"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit Team</span>
        </v-tooltip>
        <!-------------------------------------------- Delete Channel ----------------------------------------------->
        <v-tooltip top color="admin-tooltip-color">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              disabled
              small
              v-bind="attrs"
              v-on="on"
              class="admin-primary--text"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Delete Team</span>
        </v-tooltip>
        <!----------------------------------------------- Go to Chat ----------------------------------------------->
        <v-tooltip top color="admin-tooltip-color">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
              class="admin-primary--text"
              @click="openSelectdUserChat(item)"
            >
              mdi-chat
            </v-icon>
          </template>
          <span>Impersonate {{ item.full_name }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <div
      class="d-flex justify-space-between"
      :class="$vuetify.breakpoint.xs ? 'flex-column ' : 'py-4 px-8'"
    >
      <span
        class="d-flex justify-start"
        :class="$vuetify.breakpoint.xs ? 'ml-15 ' : ''"
      >
        <v-subheader class="pb-2"> Rows per Page </v-subheader>
        <v-select
          :items="perPage"
          dense
          color="#432662"
          @change="
            setPerPage($event);
            selected = true;
          "
          class="select-per-page"
          style=""
        >
          <template v-slot:append>
            <v-icon small color="admin-primary" class="py-1"
              >mdi-chevron-down</v-icon
            >
          </template>
          <template v-slot:label>
            <span
              style="font-size: 12px"
              class="pl-3"
              :class="selected ? 'd-none' : ''"
              >{{ itemsPerPage }}</span
            >
          </template>
        </v-select>
      </span>
      <!---------------------------------------------Pagination------------------------------------------------>
      <v-pagination
        color="admin-primary"
        id="#pagination"
        v-model="page"
        :length="pageCount"
        size="small"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        total-visible="5"
      ></v-pagination>
    </div>
    <!-- Modal for confirmation of archive and unarchive users -->
    <v-snackbar v-model="signoutErrorSnack" right top color="green">
      {{ signoutErrorSnackText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="signoutErrorSnack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <Snackbar :snackbar.sync="snackbar" :message="message" color="error" />
  </v-container>
</template>
<!------------------------------------------------Script-------------------------------------------------->
<script>
import { mapActions, mapGetters } from "vuex";
import Snackbar from "@/components/Snacbar";
export default {
  name: "UsersList",

  data() {
    return {
      signoutErrorSnackText: "Your session has expired !!!",
      signoutErrorSnack: false,
      loading: true,
      firstLoad: true,
      pages: 1,
      page: 1,
      select: "",
      pageCount: 0,
      itemsPerPage: 25,
      perPage: [25, 50, 100, 250, 500, 750, 1000],
      search: "",
      dialog: false,
      dialogDelete: false,
      selected: false,
      dialoginfo: false,
      selectedCompany: "",
      companiesList: [],

      //-------------------------------------------------Headers------------------------------------------------>
      headers: [
        {
          text: "Users",
          align: "start",
          filterable: false,
          value: "full_name",
          class: "admin-primary--text tableHeaderBg",
        },
        {
          text: "Email",
          value: "email",
          class: "admin-primary--text tableHeaderBg",
        },
        {
          text: "Designation",
          value: "designation",
          class: "admin-primary--text tableHeaderBg",
        },
        {
          text: "Status",
          value: "custom_status",
          class: "admin-primary--text tableHeaderBg",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "admin-primary--text tableHeaderBg",
        },
      ],
      List: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      snackbar: false,
      message: "",
    };
  },
  components: {
    Snackbar,
  },
  //----------------------------------------------Mounted------------------------------------------------>
  async beforeMount() {
    await this.userData();
  },
  mounted() {
    this.loaderFunction();
  },
  //----------------------------------------------Computed------------------------------------------------>
  computed: {
    ...mapGetters([
      "getUsersdata",
      "getTotalPages",
      "getCompaniesNames",
      "getTotalPages1",
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    Users() {
      return this.getUsersdata;
    },
  },
  //------------------------------------------------Wacthers----------------------------------------------->
  watch: {
    //watch property called on selecting company
    async select(val) {
      for (let x of this.getCompaniesNames) {
        if (val == x.name) {
          await this.CompanyUsers(x.id);
          break;
        }
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  //---------------------------------------------Methods------------------------------------------------>
  methods: {
    ...mapActions([
      "addUsers",
      "updateUsers",
      "removeUsers",
      "getUsers",
      "Companies",
      "CompanyUsers",
      "openChat",
    ]),

    //-------------------------------------------------------------------------------------------------------------------->

    async initialize() {
      await this.Companies();
      for (let x of this.getCompaniesNames) {
        this.companiesList.push(x.name);
      }
    },

    editItem(item) {
      this.editedIndex = this.Users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.Users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.Users.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveUsersData() {
      if (this.editedIndex > -1) {
        Object.assign(this.Users[this.editedIndex], this.editedItem);
      } else {
        this.Users.push(this.editedItem);
      }
      this.close();
    },
    setPerPage(perPageEntries) {
      this.itemsPerPage = perPageEntries;
    },
    rowClass() {
      return "admin-table-text";
    },
    openSelectdUserChat(obj) {
      this.openChat(obj._id).then(
        (response) => {
          if (response) {
            console.log("redirected");
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            this.snackbar = true;
            this.message = error.response.data.error;
          } else {
            this.snackbar = true;
            this.message = "Something went wrong.";
          }
        }
      );
    },
    userData() {
      this.getUsers().then(
        () => {
          this.Companies();
        },
        (error) => {
          if (error.response.status == 401) {
            this.signoutErrorSnack = true;
            let vm = this;
            setTimeout(() => {
              vm.$router.push("/");
            }, 200);
          }
        }
      );
      this.initialize();
    },
    loaderFunction: function () {
      setTimeout(() => {
        if (this.firstLoad) this.firstLoad = false;
        this.loading = false;
      }, 2000);
    },
  },
};
</script>
<!--------------------------------------------Style------------------------------------------------>
<style scoped>
.v-tab.v-tab--active {
  background-color: #432662 !important;
  border-radius: 5px;
  color: white !important;
  border-bottom: 2px solid white !important;
  border: none !important;
  text-decoration: none !important;
}
.v-tab {
  background-color: white;
  border-radius: 4px 4px 0 0 !important;
  color: #797593 !important;
  text-decoration: none !important;
  max-height: 40px !important;
  min-width: 112px !important;
}

.user-table-email-color {
  color: #a899c4;
  font-size: 14px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.v-data-footer {
  display: flex !important;
  justify-content: space-between !important;
}
.v-data-table {
  border-radius: 0px;
}
.table-text-color--text {
  color: #17123b;
  font-size: 14px;

  font-weight: bold;
}
#pagination .v-pagination .v-pagination__item,
.v-pagination .v-pagination__more,
.v-pagination {
  height: 21px !important;
  min-width: 20px !important;
  font-size: 12px !important;
}

#pagination .v-pagination__navigation .v-icon {
  font-size: 16px !important;
}
#pagination .v-pagination__navigation .v-pagination__item {
  font-size: 16px !important;
  height: 20px;
  width: 20px;
  color: #eeeff7;
}

#pagination .v-pagination__navigation {
  height: 21px !important;

  width: 20px !important;
}

.v-pagination__item .v-pagination__item--active {
  height: 12px !important;
}
.v-pagination__navigation {
  height: 12px !important;
  width: 5px !important;
}

.normal-width {
  max-width: 33% !important;
}

.select-per-page {
  box-sizing: border-box;
  height: 27.98px !important;
  max-width: 53px !important;
  border: 1px solid rgba(67, 38, 98, 0.13);
  border-radius: 2px;
  font-size: 12px !important;
  color: rgba(67, 38, 98, 0.13) !important;
}
.tableHeaderBg {
  color: #eeeff7;
}
</style>
